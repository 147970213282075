import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/Auth.context';
import { ThemeProvider } from './context/Theme.context';
import Layout from './components/Layout';
import BlueSkyMonitor from './container/BlueSkyMonitor';
import BlueSkyRules from './container/BlueSkyRules';
import StateCommissions from './container/StateCommissions';
import getConfig from './config';
import { ROUTES } from './constants/routes';
const { REACT_ENV } = getConfig();

const App = () => {
  return (
    <div className='otc-theme'>
      <ThemeProvider>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path={ROUTES.blueSkyRules} element={<BlueSkyRules />} />
              <Route path={ROUTES.stateCommissions} element={<StateCommissions />} />
              <Route path={ROUTES.blueSkyMonitor} element={<BlueSkyMonitor />} />
            </Routes>
          </Layout>
        </AuthProvider>
      </ThemeProvider>
    </div >
  );
};

export default App;
