import { library } from '@fortawesome/fontawesome-svg-core';
import {

} from '@fortawesome/pro-light-svg-icons';

import {

} from '@fortawesome/pro-regular-svg-icons';

import {
  faArrowLeftLong as faArrowLeftLongSolid,
  faUpLong as faUpLongSolid,
  faDownLong as faDownLongSolid,
  faDownload as faDownloadSolid,
  faSearch as faSearchSolid,
  faXmark as faXmarkSolid
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faArrowLeftLongSolid,
  faUpLongSolid,
  faDownLongSolid,
  faDownloadSolid,
  faSearchSolid,
  faXmarkSolid
);
