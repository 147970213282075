import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TableTabs.module.scss';

class TableTabs extends PureComponent {
  render() {
    const { position, tabs, className, selectedTab, accent, download } =
      this.props;

    return (
      <div
        className={classNames(styles.component, styles[accent], className, {
          [styles.relative]: position === 'relative',
        })}
      >
        {tabs.map((tab) => (
          <div
            key={tab.key}
            onClick={tab.func}
            className={classNames(styles.tableItem, styles[accent], {
              [styles.selected]: selectedTab === tab.key,
            })}
          >
            {tab.title}
          </div>
        ))}
        {download && (
          <div className={styles.download} onClick={download}>
            <FontAwesomeIcon icon={['fas', 'download']} size='1x' />
          </div>
        )}
      </div>
    );
  }
}

TableTabs.propTypes = {
  /** The tabs to display for the table */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      func: PropTypes.func.isRequired,
    })
  ).isRequired,
  selectedTab: PropTypes.string.isRequired,
  accent: PropTypes.string,
  className: PropTypes.string,
  download: PropTypes.func,
  position: PropTypes.oneOf(['relative', 'absolute']),
};

TableTabs.defaultProps = {
  position: 'absolute',
  accent: 'default',
};

export default TableTabs;
