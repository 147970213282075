import React from 'react';
import PropTypes from "prop-types";
import { Outline } from '@otcmarketsgroup/otcm-ui';
import Footnote from '../Footnote';
import styles from './BlueSkySummary.module.scss';
import DataRow from '../DataRow';

const showValue = value => value !== null && value !== undefined && value !== '' ? value : 'N/A';

const BlueSkySummary = ({ className, accent, data, asOfDate }) => <div className={`${[styles.container]} ${className}`}>
    <Outline mode='heading' accent={accent}>
        Summary
    </Outline>
    <DataRow title='Jurisdictions in Compliance' value={showValue(data?.inCompliance)}/>
    <DataRow title='Jurisdictions out of Compliance' value={showValue(data?.outOfCompliance)}/>
    <Footnote text={`As of ${asOfDate}`} />
</div>;

BlueSkySummary.defaultProps = {
    accent: 'green'
};

BlueSkySummary.propTypes = {
    className: PropTypes.string,
    accent: PropTypes.string,
    data: PropTypes.object,
    asOfDate: PropTypes.string
};

export default BlueSkySummary;
