import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SelectBox from 'devextreme-react/select-box';
import { getUserManual } from '../../api/bluesky';
import { jurisdictionOptions } from '../../constants/jurisdictionOptions';
import { withRouter } from '../../components/WithRouter';
import { BackButton } from '../../components/BackButton';
import { PageTitle } from '../../components/PageTitle';
import { Loading } from '../../components/Loading';
import styles from './BlueSkyRules.module.scss';
import { ROUTES } from '../../constants/routes';
import { storage } from '../../constants';


const BlueSkyRules = ({ params, navigate }) => {
    const jurisdiction = params?.jurisdiction;
    const [rulesData, setRulesData] = useState([]);
    const [rulesDataError, setRulesDataError] = useState(null);
    const [reloading, setReloading] = useState(false);
    const dataLoaded = rulesData && rulesData.length > 0;
    const hash = window.location.hash;
    const symbol = window.sessionStorage.getItem(storage.SYMBOL);

    useEffect(() => {
        setReloading(true);
        getUserManual(jurisdiction)
            .then(data => {
                setReloading(false);
                setRulesData(data);
            })
            .catch(err => {
                console.error(err);
                setReloading(false);
                setRulesDataError(err?.message || 'Error fetching data.');
            });
    }, [jurisdiction]);

    useEffect(() => {
        if (hash && hash.length > 1) {
            const el = document.getElementById(hash.substring(1));
            if (el) {
                setTimeout(() => { el.scrollIntoView(true); }, 10);
            }
        }
    }, [hash]);

    const onSelect = e => {
        const selection = e.value;
        const path = `${ROUTES.blueSkyRulesPath}/${selection}`;
        navigate(path);
    };

    const serialize = (item, index) => {
        switch (item.type) {
            case 'html': return <span key={index} dangerouslySetInnerHTML={{ __html: item.text }} />;
            case 'Regular': return <span key={index} className={styles[item.color]} dangerouslySetInnerHTML={{ __html: item.text }} />;
            case 'Bold': return <strong key={index} className={styles[item.color]}>{item.text}</strong>;
            case 'Italic': return <em key={index} className={styles[item.color]}>{item.text}</em>;
            case 'Inline': return item.nodes.map((node, i) => serialize(node, i));
            case 'OrderedList': return <ol key={index} type={item.listType} start={item.start} className={cn({
                [styles.noPadding]: item.noPadding
            })}>
                {item.nodes.map((node, i) => {
                    if (node.type === 'OrderedList') return serialize(node, i);
                    return <li key={i}>{serialize(node, i)}</li>;
                })}
            </ol>;
            case 'Break': return <br key={index} />;
        }
    };

    const renderContent = content => {
        return content.map((c, i) => <div key={i}>{serialize(c, i)}</div>);
    };

    return <div className={styles.container}>
        <BackButton to={`${ROUTES.blueSkyMonitorPath}/${symbol}`} />
        <PageTitle>Blue Sky Rules</PageTitle>
        <div>
            <div className={cn(styles.topBar, styles.hasSidebar)}>
                <div className={styles.selectContainer}>
                    {<div className={[styles.selectBox]}>
                        <SelectBox
                            className={styles.dxSelect}
                            items={jurisdictionOptions}
                            displayExpr='Name'
                            valueExpr='ID'
                            value={params?.jurisdiction}
                            searchEnabled
                            onValueChanged={onSelect} />
                    </div>}
                </div>
                {dataLoaded && <div>
                    <div className={styles.jumpLinks}>
                        {rulesData.length > 0 && rulesData.map((section, i) => {
                            if (section.hideJumpLink) return;
                            return<a key={`jump-link${i}`} href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`} className={cn(styles.linkItem, {
                                [styles.boldLink]: section.linkBold,
                                [styles.lastLine]: i !== rulesData.length - 1 && rulesData[i + 1].linkNewLine,
                                [styles.newLine]: section.linkNewLine
                            })}>{section.jumpLinkTitle || section.title}</a>;
                        })}
                    </div>
                </div>}
            </div>
            <div>
                <Loading
                    error={rulesDataError}
                    emptyMessage='No commissions found'
                    reloading={reloading}
                    loaded={dataLoaded}
                    data={rulesData}>
                    {rulesData.length > 0 && rulesData.map((section, i) =>
                        <div  key={i} className={styles.rulesBlock}>
                            <div id={section.title.replace(/[\W_]+/g, ' ').trim().replace(/ /g, '-').toLowerCase()} className={cn(styles.section, styles.sectionTitle)}>
                                {section.title && <div>{section.title}</div>}
                            </div>
                            <div key='mainContent' className={styles.mainContent}>
                                {renderContent(section.nodes)}
                            </div>
                        </div>
                    )}
                </Loading>
            </div>
        </div>
    </div>;
};

BlueSkyRules.propTypes = {
    params: PropTypes.object,
    navigate: PropTypes.func
};

export default withRouter(BlueSkyRules);