import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DateBox from 'devextreme-react/date-box';
import cn from 'classnames';
import { ThemeContext } from '../../context/Theme.context';
import { getBlueSkyData, getBlueSkySummary, getHistoricalBlueSkyData, getHistoricalBlueSkySummary } from '../../api/bluesky';
import { getSecurities } from '../../api/company';
import { format } from '../../utlis/locale';
import { disableDates, isWeekend } from '../../utlis/helper';
import CompanyHeader from '../CompanyHeader';
import ErrorPage from '../ErrorPage';
import { withRouter } from '../../components/WithRouter';
import Loading from '../../components/Loading';
import BlueSkyComplianceTable from '../../components/BlueSkyComplianceTable';
import BlueSkyComplianceMap from '../../components/BlueSkyComplianceMap';
import BlueSkySummary from '../../components/BlueSkySummary';
import BlueSkyLinks from '../../components/BlueSkyLinks';
import PageTitle from '../../components/PageTitle';
import styles from './BlueSkyMonitor.module.scss';
import SecuritiesList from '../../components/SecuritiesList';
import { storage } from '../../constants';

const minBlueSkyDate = '07/01/2020';

const BlueSkyMonitor = ({ params }) => {
    const [themeState] = useContext(ThemeContext);
    const [blueSkyStats, setBlueSkyStats] = useState(null);
    const [blueSkyData, setBlueSkyData] = useState(null);
    const [asOfDate, setAsOfDate] = useState(null);
    const [recentDate, setRecentDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [reloading, setReloading] = useState(false);
    const [currentSecurity, setCurrentSecurity] = useState('');
    const [securities, setSecurities] = useState(null);
    const [blueskyError, setBlueSkyError] = useState('');
    const [showErrorPage, setErrorPage] = useState(false);
    const symbol = params?.symbol;
    const blueskyLoaded = !!blueSkyStats && !!blueSkyData && themeState.isReady;
    const accentColor = themeState.color;
    const hasAdditionalSecurities = securities && securities.length > 0;

    useEffect(() => {
        window.sessionStorage.setItem(storage.SYMBOL, symbol);

        if (asOfDate) setReloading(true);

        getSecurities()
            .then(data => {
                const currentSecurity = data.find(item => item.symbol.toLowerCase() === symbol.toLowerCase());
                const otherSecurities = data.filter(item => item.symbol.toLowerCase() !== symbol.toLowerCase());
                setCurrentSecurity(currentSecurity.company?.name);
                setSecurities(otherSecurities);
            })
            .catch(error => console.error(`Failed Securities call: `, error));

        loadLatestBlueSkyData();
    }, [symbol]);

    const loadLatestBlueSkyData = _ => {
        const apiParams = {
            symbol
        };

        if (asOfDate) setReloading(true);

        Promise.all([getBlueSkySummary(apiParams), getBlueSkyData(apiParams)])
            .then(([summaryData, blueskyData]) => {
                const asOfDate = format(blueskyData?.blueskyDate, 'N/A', 'staticDate');

                setRecentDate(asOfDate);
                setAsOfDate(asOfDate);
                setSelectedDate(asOfDate);
                setReloading(false);
                setBlueSkyData(blueskyData?.results);
                setBlueSkyStats(summaryData);
            })
            .catch(error => {
                if (error?.response?.status === 403) {
                    setErrorPage(true);
                    return;
                }

                setReloading(false);
                setBlueSkyError(error?.response?.data?.error || 'Error fetching Blue Sky Data.');
            });
    };

    const loadHistoryicalBlueSkyData = date => {
        setReloading(true);

        const apiParams = {
            symbol,
            date: format(date, date, 'isoDate')
        };

        Promise.all([getHistoricalBlueSkySummary(apiParams), getHistoricalBlueSkyData(apiParams)])
            .then(([summaryData, blueskyData]) => {
                const asOfDate = format(date, 'N/A', 'staticDate');

                setAsOfDate(asOfDate);
                setReloading(false);
                setBlueSkyData(blueskyData?.results);
                setBlueSkyStats(summaryData);
            })
            .catch(error => {
                setReloading(false);
                console.error(err, 'data error');
            });
    };

    const handleDateChange = date => {
        const enteredDate = date;


        if (isWeekend(enteredDate)) {
            setSelectedDate(selectedDate);
        } else {
            const isSameDate = format(recentDate, recentDate, 'staticDate') === format(enteredDate, enteredDate, 'staticDate');

            if (isSameDate && new Date(recentDate).getTime() === new Date(enteredDate).getTime()) {
                loadLatestBlueSkyData();
            } else {
                loadHistoryicalBlueSkyData(enteredDate);
            }

            setSelectedDate(enteredDate);
        }
    };

    return showErrorPage ? <ErrorPage /> : <div className={styles.container}>
        <CompanyHeader className='mbXL' symbol={symbol} />
        <Loading loaded={blueskyLoaded} reloading={reloading} error={blueskyError}>
            <div className={styles.header}>
                <div className={styles.main}>
                    <PageTitle>Blue Sky Monitoring</PageTitle>
                    {asOfDate && <DateBox
                        className={cn(styles.dateBox, styles[accentColor], accentColor)}
                        value={selectedDate}
                        type='date'
                        min={minBlueSkyDate}
                        max={new Date()}
                        disabledDates={disableDates}
                        onValueChange={handleDateChange}
                        invalidDateMessage='Value must be a date'
                    />}
                </div>
            </div>
            <div className={styles.dataContainer}>
                <div className={styles.main}>
                    <BlueSkySummary className={cn(styles.summaryMobile, 'mbXL')} data={blueSkyStats} asOfDate={asOfDate} accent={accentColor} />
                    <BlueSkyComplianceMap className='mbXL' data={blueSkyData} asOfDate={asOfDate} accent={accentColor} />
                    <div>
                        <BlueSkyComplianceTable data={blueSkyData} asOfDate={asOfDate} symbol={symbol} accent={accentColor} />
                    </div>
                </div>
                <div className={styles.side}>
                    <BlueSkySummary className={cn(styles.summaryDesktop, 'mbXL')} data={blueSkyStats} asOfDate={asOfDate} accent={accentColor} />
                    {hasAdditionalSecurities && <SecuritiesList className='mbXL' securityName={currentSecurity} securities={securities} accent={accentColor} />}
                    <BlueSkyLinks className='mbXL' accent={accentColor} />
                    <div key='info' className={cn(styles.info, styles[accentColor])}>
                        <p>
                            The Blue Sky Monitoring Service offers a customized, daily audit of your company's compliance status in each U.S. state or territory. Broker dealers must confirm that securities are compliant with the Blue Sky laws of a given jurisdiction before recommending them to investors residing there. This service will help you identify compliance barriers that may restrict trading in your company's stock.
                        </p>
                    </div>
                </div>
            </div>
        </Loading>
    </div>;
};

BlueSkyMonitor.propTypes = {
    params: PropTypes.object,
};

export default withRouter(BlueSkyMonitor);