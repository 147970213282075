export const jurisdictionOptions = [
  {
    Name: 'Alaska (AK)',
    ID: 'alaska'
  },
  {
    Name: 'Alabama (AL)',
    ID: 'alabama'
  },
  {
    Name: 'Arkansas (AR)',
    ID: 'arkansas'
  },
  {
    Name: 'Arizona (AZ)',
    ID: 'arizona'
  },
  {
    Name: 'California (CA)',
    ID: 'california'
  },
  {
    Name: 'Colorado (CO)',
    ID: 'colorado'
  },
  {
    Name: 'Connecticut (CT)',
    ID: 'connecticut'
  },
  {
    Name: 'District of Columbia (DC)',
    ID: 'district_of_columbia'
  },
  {
    Name: 'Delaware (DE)',
    ID: 'delaware'
  },
  {
    Name: 'Florida (FL)',
    ID: 'florida'
  },
  {
    Name: 'Georgia (GA)',
    ID: 'georgia'
  },
  {
    Name: 'Guam (GU)',
    ID: 'guam'
  },
  {
    Name: 'Hawaii (HI)',
    ID: 'hawaii'
  },
  {
    Name: 'Idaho (ID)',
    ID: 'idaho'
  },
  {
    Name: 'Illinois (IL)',
    ID: 'illinois'
  },
  {
    Name: 'Indiana (IN)',
    ID: 'indiana'
  },
  {
    Name: 'Iowa (IA)',
    ID: 'iowa'
  },
  {
    Name: 'Kansas (KS)',
    ID: 'kansas'
  },
  {
    Name: 'Kentucky (KY)',
    ID: 'kentucky'
  },
  {
    Name: 'Louisiana (LA)',
    ID: 'louisiana'
  },
  {
    Name: 'Maine (ME)',
    ID: 'maine'
  },
  {
    Name: 'Maryland (MD)',
    ID: 'maryland'
  },
  {
    Name: 'Massachussets (Ma)',
    ID: 'massachussets'
  },
  {
    Name: 'Michigan (MI)',
    ID: 'michigan'
  },
  {
    Name: 'Minnesota (MN)',
    ID: 'minnesota'
  },
  {
    Name: 'Mississippi (MS)',
    ID: 'mississippi'
  },
  {
    Name: 'Missouri (MO)',
    ID: 'missouri'
  },
  {
    Name: 'Montana (MT)',
    ID: 'montana'
  },
  {
    Name: 'Nebraska (NE)',
    ID: 'nebraska'
  },
  {
    Name: 'Nevada (NV)',
    ID: 'nevada'
  },
  {
    Name: 'New Hampshire (NH)',
    ID: 'new_hampshire'
  },
  {
    Name: 'New Jersey (NJ)',
    ID: 'new_jersey'
  },
  {
    Name: 'New Mexico (NM)',
    ID: 'new_mexico'
  },
  {
    Name: 'New York (NY)',
    ID: 'new_york'
  },
  {
    Name: 'North Carolina (NC)',
    ID: 'north_carolina'
  },
  {
    Name: 'North Dakota (ND)',
    ID: 'north_dakota'
  },
  {
    Name: 'Ohio (OH)',
    ID: 'ohio'
  },
  {
    Name: 'Oklahoma (OK)',
    ID: 'oklahoma'
  },
  {
    Name: 'Oregon (OR)',
    ID: 'oregon'
  },
  {
    Name: 'Pennsylvania (PA)',
    ID: 'pennsylvania'
  },
  {
    Name: 'Puerto Rico (PR)',
    ID: 'puerto_rico'
  },
  {
    Name: 'Rhode Island (RI)',
    ID: 'rhode_island'
  },
  {
    Name: 'South Carolina (SC)',
    ID: 'south_carolina'
  },
  {
    Name: 'South Dakota (SD)',
    ID: 'south_dakota'
  },
  {
    Name: 'Tennessee (TN)',
    ID: 'tennessee'
  },
  {
    Name: 'Texas (TX)',
    ID: 'texas'
  },
  {
    Name: 'Utah (UT)',
    ID: 'utah'
  },
  {
    Name: 'Vermont (VT)',
    ID: 'vermont'
  },
  {
    Name: 'Virginia (VA)',
    ID: 'virginia'
  },
  {
    Name: 'Virgin Islands (VI)',
    ID: 'virginislands'
  },
  {
    Name: 'Washington (WA)',
    ID: 'washington'
  },
  {
    Name: 'West Virginia (WV)',
    ID: 'west_virginia'
  },
  {
    Name: 'Wisconsin (WI)',
    ID: 'wisconsin'
  },
  {
    Name: 'Wyoming (WY)',
    ID: 'wyoming'
  }
];
