import api from './api';

export async function getCommissions() {
  const response = await api.request({
    method: 'get',
    url: '/state-security-commissions',
    isOTC: true
  });
  return response.data;
};
