import React from 'react';
import PropTypes from "prop-types";
import { Outline } from '@otcmarketsgroup/otcm-ui';
import Loading from '../Loading';
import USMap from '../USMap';
import Footnote from '../Footnote';
import styles from './BlueSkyComplianceMap.module.scss';

const BlueSkyComplianceMap = ({ className, accent, data, asOfDate }) => <div className={`${[styles.container]} ${className}`}>
    <Outline mode='heading' accent={accent}>
        Blue Sky Compliance Map
    </Outline>
    <Loading
            type='table'
            loaded={data}
            data={data}>
        <USMap className={styles.map} accent={accent} data={data} />
    </Loading>
    <Footnote text={`As of ${asOfDate}`} />
</div>;

BlueSkyComplianceMap.defaultProps = {
    accent: 'green',
};

BlueSkyComplianceMap.propTypes = {
    className: PropTypes.string,
    accent: PropTypes.string,
    data: PropTypes.array,
    asOfDate: PropTypes.string
};

export default BlueSkyComplianceMap;
