import React, { useEffect, useState, useRef } from 'react';
import { getCommissions } from '../../api/state-sec-commissions';
import DataGrid, {
    Column
} from 'devextreme-react/data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { BackButton } from '../../components/BackButton';
import { Loading } from '../../components/Loading';
import { DisplayResults } from '../../components/DisplayResults';
import PageTitle from '../../components/PageTitle';
import DirSearch from '../../components/DirSearch';
import { Outline } from '@otcmarketsgroup/otcm-ui';
import styles from './StateCommissions.module.scss';
import { format } from '../../utlis/locale';

const StateCommissions = _ => {
    const dataGridRef = useRef(null);
    const [stateData, setStateData] = useState(null);
    const [fullDataSet, setFullDataSet] = useState(null);
    const [totalRecords, setTotalRecords] = useState(null);
    const [stateDataError, setStateDataError] = useState(null);
    const dataLoaded = !!fullDataSet;

    useEffect(() => {
        getCommissions()
            .then(data => {
                setTotalRecords(data.totalRecords);
                setFullDataSet(data.records);
                setStateData(data.records);
            })
            .catch(err => {
                console.error(err);
                setStateDataError(err?.message || 'Error fetching data.');
            });
    }, []);

    const onSearch = searchQuery => {
        const updateData = fullDataSet.filter(item => item.jurisdiction.toLowerCase().includes(searchQuery.toLowerCase()));
        setStateData(updateData);
    };

    const handleExportClick = () => {
        const dataGrid = dataGridRef.current.instance;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('State Securities Commissions');

        exportDataGrid({
            component: dataGrid,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `State Securities Commissions ${format(new Date(), new Date(), 'date')}.xlsx`);
            });
        }).then(function () {
            dataGrid.columnOption('ID', 'visible', false);
            dataGrid.endUpdate();
        });
    };

    return <div className={styles.container}>
        <BackButton />
        <PageTitle>State Securities Commissions</PageTitle>
        <div className={styles.content}>
            <div className={styles.search}>
                <DirSearch onSearch={onSearch} />
            </div>
            <div className={styles.main}>
                <div className='relative'>
                    <Outline mode='heading'>
                        State Securities Commissions
                    </Outline>
                    <FontAwesomeIcon className={styles.download} icon={['fas', 'download']} size='lg' onClick={handleExportClick} />
                </div>
                <Loading loaded={dataLoaded} error={stateDataError}>
                    <DataGrid
                        ref={dataGridRef}
                        dataSource={stateData}
                        columnAutoWidth
                        paging={{ enabled: false }}
                        sorting={{ mode: 'none' }}
                    >
                        <Column
                            caption='Jurisdiction'
                            dataField='jurisdiction'
                            allowExporting
                        />
                        <Column
                            caption='Commissioner/Contact'
                            dataField='commissioner'
                            allowExporting
                        />
                        <Column
                            caption='Phone'
                            dataField='phone'
                            allowExporting
                        />
                        <Column
                            caption='Website'
                            dataField='website'
                            allowExporting
                            cellRender={data => <a className={'cell-wrap'} href={data?.data.website} target='_blank' rel='noopener noreferrer'>{data?.data.website}</a>}
                        />
                    </DataGrid>
                    {stateData && <DisplayResults show={stateData?.length} total={totalRecords} text={'Commissions'} />}
                </Loading>
            </div>
        </div>
    </div>;
};

export default StateCommissions;