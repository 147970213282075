import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './EntityButton.module.scss';

const EntityButton = ({ className, accent, title, subTitle, to}) => <Link className={cn(className, styles.link, styles[accent])} to={to}>
  <div>
    {title}
  </div>
  {subTitle && <b>
    {subTitle}
  </b>}
</Link>;

EntityButton.defaultProps = {
  accent: 'green'
};

EntityButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  to: PropTypes.string,
  accent: PropTypes.string
};

export default EntityButton;
