import React from 'react';
import PropTypes from "prop-types";
import EntityButton from '../EntityButton';
import { ROUTES } from '../../constants/routes';

const BlueSkyLinks = ({ className, accent }) => <div className={className}>
  <EntityButton
    className='mbSm'
    to={`${ROUTES.blueSkyRulesPath}/alaska`}
    title='Blue Sky Rules'
    subTitle='View'
    accent={accent}
  />
  <EntityButton
    to={ROUTES.stateCommissions}
    title='State Securities Commissions'
    subTitle='View'
    accent={accent}
  />
</div>;

BlueSkyLinks.defaultProps = {
  accent: 'green'
};

BlueSkyLinks.propTypes = {
  className: PropTypes.string,
  accent: PropTypes.string
};

export default BlueSkyLinks;
