import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { Outline, Table } from '@otcmarketsgroup/otcm-ui';
import Footnote from '../Footnote';
import TableTabs from '../TableTabs';
import styles from './BlueSkyComplianceTable.module.scss';

const BOTH = 'BOTH';
const IN_COMPLIANCE = 'IN_COMPLIANCE';
const OUT_OF_COMPLIANCE = 'OUT_OF_COMPLIANCE';

const complianceColumns = [
  { dataField: 'state', caption: 'Jurisdiction', allowExporting: true },
  { dataField: 'status', caption: 'In Compliance', allowExporting: true },
  {
    dataField: 'exemption', caption: 'Exemptions/Registrations*', allowExporting: true, cellRender: item => item?.data?.status === 'Y' && !!item?.data?.exemptions && item.data.exemptions.length > 0 ? <div className='cell-wrap'>{item.data.exemptions.join(', ')}</div> : null
  }
];

const BlueSkyComplianceTable = ({ accent, data, asOfDate, symbol }) => {
  const dataGridRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('BOTH');
  const [columns, setColumns] = useState(complianceColumns);
  const [dataSet, setDataSet] = useState(null);
  const [smallView, setSmallView] = useState(false);

  useEffect(() => {
    setDataSet([...data]);
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      // Check browser width and toggle columns accordingly
      if (window.innerWidth < 768) {
        setSmallView(true);
        setColumns(columns.filter(column => column.dataField !== 'exemption'));
      } else {
        setSmallView(false);
        setColumns(complianceColumns);
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [columns]);  

  const tableTabs = [
    {
      key: BOTH,
      title: 'All',
      func: _ => {
        setSelectedTab(BOTH);
        setDataSet(data);
      }
    },
    {
      key: IN_COMPLIANCE,
      title: 'In Compliance',
      func: _ => {
        setSelectedTab(IN_COMPLIANCE);
        setDataSet(data.filter(item => item.status === 'Y'));
      }
    },
    {
      key: OUT_OF_COMPLIANCE,
      title: 'Out of Compliance',
      func: _ => {
        setSelectedTab(OUT_OF_COMPLIANCE);
        setDataSet(data.filter(item => item.status === 'N'));
      }
    }
  ];

  const handleExportClick = () => {
    const dataGrid = dataGridRef.current.instance;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Blue Sky');

    exportDataGrid({
      component: dataGrid,
      worksheet: worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        const data = gridCell.data;

        if (gridCell.rowType === 'data' && gridCell.column.dataField === 'exemption') {
          excelCell.value = (data.status === 'Y' && data.exemptions && data.exemptions.length > 0) ? data.exemptions.join(', ') : '';
        }
      }
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${symbol} Blue Sky Compliance ${asOfDate}.xlsx`);
      });
    }).then(function () {
      dataGrid.columnOption('ID', 'visible', false);
      dataGrid.endUpdate();
    });
  };

  return <div className={styles.container}>
    <Outline mode='heading' accent={accent}>
      Blue Sky Compliance
    </Outline>
    <TableTabs
      tabs={tableTabs}
      selectedTab={selectedTab}
      download={handleExportClick}
      accent={accent}
    />
    <DataGrid
      ref={dataGridRef}
      dataSource={dataSet}
      columnAutoWidth
      paging={{ enabled: false }}
      sorting={{ mode: 'none' }}
      loadPanel={{ enabled: false }}
      noDataText='Not available'
    >
      {columns.map(column => (
        <Column key={column.dataField} dataField={column.dataField} caption={column.caption} allowExporting cellRender={column.cellRender && column.cellRender} />
      ))}
    </DataGrid>
    <Footnote text={`As of ${asOfDate}`} />
    {!smallView && <Footnote>
      *Exemption/Registration Type: Blue Sky laws govern the offer and sale of securities at the state level. While registration is generally required in each state when a company is offering or selling their securities to residents of that state, many states provide exemptions to registration for secondary trading in the OTC market for companies that provide current disclosure and/or meet certain financial and seasoning requirements. For more information on these secondary trading exemptions, see <Link to={`${ROUTES.blueSkyRulesPath}/alaska`}>Blue Sky Rules</Link>.
    </Footnote>}
  </div>;
};

BlueSkyComplianceTable.defaultProps = {
  accent: 'green',
};

BlueSkyComplianceTable.propTypes = {
  accent: PropTypes.string,
  data: PropTypes.array,
  asOfDate: PropTypes.string,
  symbol: PropTypes.string
};

export default BlueSkyComplianceTable;
