import api from './api';
// import { getToken } from '../../../utils/session';

export async function getBlueSkySummary(params) {
    const response = await api.request({
        method: 'get',
        url: '/bluesky/daily/stats',
        isOTC: true,
        params,
    });
    return response.data;
}

export async function getBlueSkyData(params) {
    const response = await api.request({
        method: 'get',
        url: '/bluesky/daily/results',
        isOTC: true,
        params: {
            ...params,
            pageSize: 60
        },
    });
    return response.data;
}

export async function getHistoricalBlueSkySummary(params) {
    const response = await api.request({
        method: 'get',
        url: '/bluesky/historical/stats',
        isOTC: true,
        params,
    });
    return response.data;
}

export async function getHistoricalBlueSkyData(params) {
    const response = await api.request({
        method: 'get',
        url: '/bluesky/historical/results',
        isOTC: true,
        params: {
            ...params,
            pageSize: 60
        },
    });
    return response.data;
}

export async function getUserManual(jurisdiction) {
    const response = await api.request({
        method: 'get',
        isOTC: true,
        url: `/bluesky/manual/${jurisdiction}`,
    });

    return response.data;
}
