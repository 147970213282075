import React from 'react';
import PropTypes from 'prop-types';
import styles from './DataRow.module.scss';

const DataRow = ({ title, value }) => <div className={styles.container}>
  <div className={styles.title}>
    {title}
  </div>
  <div className={styles.value}>
    {value}
  </div>
</div>;

DataRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
};

export default DataRow;
