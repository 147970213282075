import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Popup } from 'devextreme-react/popup';

const SymbolLink = (props) => {
  const [popupVisible, setPopup] = useState(false);
  const symbol = props.symbol || props.data.symbol || props.data.ticker;
  const disableLink = props.disableLink;
  let tier =
    props.tier ||
    (props.data &&
      (props.data.tierId || props.data.tierCode || props.data.tierGroup));
  if (!isNaN(parseInt(tier))) tier = parseInt(tier);
  if (
    !props.disableCaveatEmptor &&
    props.data &&
    props.data.isCaveatEmptor === true
  )
    tier = 'CE';

  let tierIcon = null;

  switch (tier) {
    case -1:
    case 'CE':
      tierIcon = 'caveat-emptor';
      break;

    case 1:
    case 2:
    case 5:
    case 6:
    case 'OTCQX':
    case 'QX':
    case 'QXUSP':
    case 'QXUS':
    case 'QXIP':
    case 'QXI':
      tierIcon = 'qx'; // QX
      break;

    case 10:
    case 'OTCQB':
    case 'QB':
    case 'DQ':
      tierIcon = 'qb'; // QB
      break;

    case 20:
    case 'PS':
    case 'PC':
      tierIcon = 'ps'; // Pink
      break;

    case 21:
    case 'PL':
      tierIcon = 'yield'; // Pink Limited Info
      break;

    case 22:
    case 'PN':
      tierIcon = 'stop'; // Pink No Info
      break;

    case 30:
    case 'OO':
    case 'GM':
      tierIcon = 'caution'; // Grey Market
      break;

    case 40:
    case 'EM':
      tierIcon = 'expert-tier'; // Expert Tier
      break;

    default:
      break;
  }

  return (
    <span
      className={classNames(styles.container, tierIcon && styles.hasIcon, {
        [styles.large]: props.large,
      })}
    >
      {tierIcon && (
        <div className={styles.tierIcon}>
          <img src={`/icons/tier/${tierIcon}.png`} />
        </div>
      )}
      {disableLink ? symbol : props.entitled ? <Link to={`/blue-sky/${symbol}`}>{symbol}</Link> : <div className={styles.link} onClick={(_ => setPopup(true))}>{symbol}</div>}
      <Popup
        visible={popupVisible}
        dragEnabled={false}
        hideOnOutsideClick
        showCloseButton
        position="center"
        width={550}
        height={150}
        onHidden={() => setPopup(false)}
      >
        <div>{symbol} is currently not entitled to Blue Sky Monitoring. Contact Issuer Services at 212-896-4420 if you'd like to get this security added.</div>
      </Popup>
    </span>
  );
};

SymbolLink.propTypes = {
  data: PropTypes.object,
  tier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  symbol: PropTypes.string,
  disableCaveatEmptor: PropTypes.bool,
  disableLink: PropTypes.bool,
  large: PropTypes.bool,
  entitled: PropTypes.bool
};

export default SymbolLink;
